/**
    SCSS for Thank You Page
    Author: Matt Parkes
    Standard SCSS for thank you page.
**/


.thankyouContainer {
    color: #373636;
    h1:not(.h1) {
        font-weight: 800;
        font-size: 40px;
        line-height: 47px;
        color: #373636;
    }

    .user-data-block {
        .single-user-data {
            font-size: 14px;
            line-height: 21px;
            font-weight: 800;
        }
    }

    p {
        font-size: 14px;
        font-weight: 800;
        line-height: 21px;
    }
}

.loginContainer {
    h1:not(.h1) {
        font-weight: 800;
        font-size: 40px;
        line-height: 47px;
        color: #373636;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #373636;
    }

    a {
        color: #373636 !important;
        text-decoration: underline;
        font-weight: 800;
    }
}

/** 
    End Of SCSS for Thank You Page
**/