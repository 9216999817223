
$breadcrumb-divider: quote(">");

@import '~mdb-ui-kit/src/mdb/scss/mdb.pro.scss';
// @import '~mdb-color-picker/css/color-picker.min.css'; // plugin
@import '~mdb-file-upload/css/file-upload.min.css'; // plugin
@import '~mdb-wysiwyg-editor/css/wysiwyg.min.css'; // plugin

@import '~swiper/swiper.scss';
@import '~swiper/swiper-bundle.css';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

@import '~@iconscout/unicons/css/line.css';
@import '~@iconscout/unicons/css/thinline.css';

$my-theme-primary: #DB9123; // theme primary color, change this value to customize theme
$my-theme-secondary: #152944; // theme secondary color, change this value to customize theme
$my-theme: mdb-light-theme($my-theme-primary, $my-theme-secondary);


// include theme styles
@include mdb-theme($my-theme);

//font-family: 'Lato', sans-serif;

html, body, main {
    // height: 100%;
    font-family: 'Lato', sans-serif;
    scroll-behavior: smooth;
}

h1, .h1 {
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    color: #373636;
    font-family: 'Raleway', sans-serif;
}

h2, .h2 {
    font-size: 14px;
    font-weight: 800;
    line-height: 16px; 
    color: #373636;
    font-family: 'Raleway', sans-serif;
}

h3, .h3 {
    font-family: 'Raleway', sans-serif;
}

h4, .h4 {
    font-family: 'Raleway', sans-serif;
}

h5, .h5 {
    font-family: 'Raleway', sans-serif;
}

h6, .h6 {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 800;
}

th {
    font-family: 'Raleway', sans-serif;
    font-weight: 800 !important;
}

#main-navbar {
    .uil {
        line-height: 20px;
        font-size: 20px;
    }
}

@import "registration";
@import "login_thankyou";
@import "dashboard";