$red: #b41f1f;
$amber: #f7b900;
$green: #1d9f67;

html,
body,
main {
    min-height: 100vh;
}

main {
    display: flex;
    flex-direction: column;
    > .container-fluid {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        > .row {
            flex-grow: 1;
        }
    }
}


.chip {
    background-color: #f1f1f1!important;
    height: fit-content !important;
}

// Bootstrap overrides

.bg-success {
    background-color: #2fb150 !important;
}

.bg-danger {
    background-color: #cb252e !important;
}

header {
    height: 0px;
}

body {
    // background-color: #f1f1f1;
}

#main-navbar {
    a {
        color: white;
    }

    a.dropdown-item {
        color: #373636;
        &:hover {
            background-color: rgba(219, 145, 35, 0.1);
        }
    }

    i {
        color: white !important;
    }
}

#sidenav-left {
    height: calc(100vh - 58px) !important;
    top: 58px !important;

    background-color: #222932;
    background-blend-mode: overlay;
    background-size: cover;

    .sidenav-item {
        i.orange {
            background-color: $my-theme-primary;
            border-radius: 50%;
            padding: 8px;
            color: white;
            font-size: 20px;
        }
    }

    .overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #172943;
        opacity: 0.8;
    }

    .left-bg {
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .sidenav-menu {
        a {
            color: white;
            font-weight: 800;
            line-height: 16px;
            border-radius: 0px;
        }
    }

    .fa-chevron-down {
        margin-left: 8px;
    }
}

#side-bottom {
    .sidenav-item {
        span {
            color: white;
        }
        i {
            background-color: transparent;
            color: $my-theme-primary;
        }
    }
}

#content {
    // max-width: calc(100vw - 240px);
    color: #373636;
    // height: calc(100vh - 58px) !important;
    min-height: calc(100vh - 58px) !important;
    background-color: #f1f1f1;
    .card {
        box-shadow: 0 1.3px 0.8px rgba(0, 0, 0, 0.06),
            0 3.2px 2.4px rgba(0, 0, 0, 0.046),
            0 5.9px 5.1px rgba(0, 0, 0, 0.041),
            0 10px 9.5px rgba(0, 0, 0, 0.038),
            0 17px 15.9px rgba(0, 0, 0, 0.034),
            0 32.3px 22.7px rgba(0, 0, 0, 0.029),
            0 86px 26px rgba(0, 0, 0, 0.02);
        border-radius: 0px;
        .card-title {
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 800;
            line-height: 24px;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
        }

        &.account-details {
            height: 100%;
            p {
                font-size: 14px;
                font-weight: 800;
                line-height: 21px;
            }
        }

        .card-body {
            padding: 30px;
            .edit-account-details,
            .edit-organisation-details,
            .edit-payment-details,
            .edit-organisation-details,
            .edit-default {
                width: fit-content;
                cursor: pointer;
                i {
                    color: $my-theme-primary;
                }
            }
        }
    }

    .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }
}

.breadcrumb {
    .breadcrumb-item {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        z-index: 999;
    }
}

.setting-sidebars {
    height: calc(100vh - 58px) !important;
    top: 58px !important;

    background-color: #222932;
    background-blend-mode: overlay;
    padding-bottom: 40px;
    background-size: cover;

    .bg {
        height: 100%;
        background-size: cover;
        background-position: center;
    }
    .overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #172943;
        opacity: 0.8;
    }
    .sidebar-title {
        font-size: 20px;
        font-weight: 800;
        line-height: 24px;
        color: #ffffff !important;
        text-transform: uppercase !important;
        text-align: left !important;
    }

    .sidebar-subtitle {
        font-size: 14px;
        font-weight: 800;
        line-height: 24px;
        color: #ffffff !important;
        text-align: left !important;
    }

    .sidebar-form-row {
        margin-left: 0px;
        margin-right: 0px;
        .form-label {
            font-weight: 800;
            color: #ffffff;
            font-size: 14px;
            line-height: 16px;
            padding-right: 0px;
            padding-left: 0px;
            margin-bottom: 5px;
        }
        p {
            color: #ffffff;
        }
    }

    #credits-step-labels {
        color: #ffffff;
    }

    .close-sidebar {
        background-color: #db9123;
        outline: none;
        border: none;
        height: 28px;
        border-radius: 50%;
        width: 28px;
        i {
            color: white;
        }
    }

    .subtitle {
        color: #ffffff;
    }

    &.default {
        width: 30vw !important;
    }

    .select-wrapper {
        padding-left: 0px;
        padding-right: 0px;
        background-color: white;
    }
    .range {
        padding-left: 0px;
        padding-right: 0px;
    }

    .credit-text {
        color: #ffffff;
    }

    .form-check-label {
        color: #ffffff;
        font-size: 14px;
        font-weight: 800;
    }

    .form-check-input:not(:checked) {
        border-color: #ffffff;
    }

    .uil {
        color: #ffffff;
    }

    .required-fields {
        font-size: 12px;
        font-weight: normal;
        line-height: 21px;
    }
}

#password-success {
    background-color: #98d722 !important;
}

.color-picker-range-alpha {
    display: none;
}

.organisation-logo {
    height: auto;
    width: 150px;
}

.color-box {
    height: 50px;
    width: 50px;
    border: 1px solid black;
}

.file-upload {
    .file-upload-message {
        p {
            color: #172943;
        }
    }
}

.color-picker-change-view-icons {
    button {
        color: white;
    }
}

.file-upload-file-name {
    word-wrap: break-word;
}

.uil {
    font-size: 24px;
}

/* User management */

#content .datatable {
    .actions {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        i {
            color: #db9123;
            cursor: pointer;
            margin: 0px 5px;
        }
    }

    .datatable-pagination {
        .datatable-select-wrapper {
            display: none;
        }
        flex-direction: column-reverse;
        justify-content: center;
        .datatable-pagination-buttons {
            margin-left: 0px;
            .datatable-pagination-button {
                i {
                    color: #db9123;
                }
            }
        }
    }

    th {
        font-size: 20px;
        font-weight: 800;
        line-height: 24px;
        text-transform: uppercase;
    }

    th:first-child {
        padding: 1rem 1.4rem !important;
    }

    th:last-child {
        display: none;
    }

    .table-grab,
    .section-icon {
        font-size: 24px;
        color: #373636;
    }

    .section-icon {
        color: #db9123;
        cursor: auto;
    }


    td:first-child, th:first-child {
        padding: 1rem 1rem 1rem 0rem;
    }

    th:not(:last-child), td:not(:last-child) {
        border-right: 1px solid #E9E9E9;
    }
    td:first-child,
    th:first-child {
        padding: 1rem 1rem 1rem 0rem;
    }
    th:not(:last-child),
    td:not(:last-child) {
        border-right: 1px solid #e9e9e9;
    }

    td {
        line-height: 20px;
        font-weight: 400;
        vertical-align: middle;
        &:first-child {
            i {
                font-size: 24px;
                color: $my-theme-primary;
                margin-right: 10px;
            }
        }
    }

    .survey-dropdown,
    .item-dropdown {
        p {
            margin-bottom: 0px;
        }

        border-bottom: 1px solid #e0e0e0;

        td {
            border-right: 1px solid #e0e0e0;
            padding-left: 65px;
        }
    }

    td[data-mdb-field="description"] {
        display: none;
    }
}

.ui-sortable-helper {
    display: table;
}

.table-container {
    position: relative;
    .add-user-plus {
        position: fixed;
        right: 20px;
        bottom: 20px;
        cursor: pointer;
        i {
            font-size: 50px;
            color: #db9123;
        }
    }
}

.datatable-sort-icon {
    color: #db9123 !important;
    font-size: 14px;
    margin-top: 2px;
}

.fas {
    cursor: pointer;
}

.page_header {
    .dropdown-menu {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 20px #0000005c;
        border-radius: 0px;
        padding: 20px 10px;
        li a {
            padding: 3px 10px;
            font-size: 14px;
            font-weight: 800;
            line-height: 21px;
        }
        i {
            margin-right: 10px;
            color: $my-theme-primary;
        }
    }
}

#survey_actions {
    line-height: 24px;
}

.guidance-notes a {
    border: none;
    padding: 0.25rem 0.5rem;
    color: #373636 !important;
    line-height: 28px;
    &.list-group-item-action:hover {
        border-radius: 0px;
    }
}

.start-typing {
    background-color: #f1f1f1;
    border: none;
    font-weight: 400;
    font-size: 14px;
    &:focus-visible {
        outline: none;
    }
    &::placeholder {
        color: #373636;
    }
}

.card {
    &.main-section {
        .row {
            padding-right: 1rem !important;
            padding-left: 1rem !important;
        }
    }
}

#section-management {
    padding: 0px;
}

#item-management.datatable {
    .single-item-question,
    .actions,
    .item-questions {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
    }

    .single-item-question-title {
        line-height: 21px;
        font-size: 14px;
        font-weight: 400;
        white-space: normal;
    }

    .actions {
        i {
            font-size: 14px !important;
        }
    }
}

.tag-main-container {
    display: flex;
    .tag-container {
        background-color: #f1f1f1;
        border-radius: 50px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding: 0px 8px;
        margin: 5px;
        color: #373636;
        line-height: 21px;
        font-size: 12px;
        font-weight: 600;
        &:first-child {
            margin-left: 0px;
        }

        &:last-child {
            margin-right: 0px;
        }
    }
}

/* End User management */

.edit-survey {
    .select-wrapper {
        display: inline-block;
    }

    .chips-padding {
        padding: 0px;
    }

    .chip {
        margin-top: 0px;
    }

    .form-notch {
        display: none;
    }

    #survey_tags {
        margin-bottom: 5px;
        height: 32px;
    }
}

.form-control {
    border-radius: 0px;
}

.publish-type {
    font-size: 12px;
}

@media screen and (max-width: 992px) {
}

// Generic Datatable styles
#content .datatable.crud-datatable {
    i {
        color: #db9123;
    }

    .edit-data,
    .delete-modal-data {
        margin: 2px;
        cursor: pointer;
        i {
            color: #db9123;
        }
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        i {
            margin-left: 6px;
        }
    }

    .datatable-pagination {
        .datatable-select-wrapper {
            display: none;
        }
        flex-direction: column-reverse;
        justify-content: center;
        .datatable-pagination-buttons {
            margin-left: 0px;
            .datatable-pagination-button {
                i {
                    color: #db9123;
                }
            }
        }
    }

    th {
        font-size: 20px;
        font-weight: 800 !important;
        line-height: 24px;
        text-transform: uppercase;
    }

    table thead tr > th:first-child {
        padding: 1rem 0rem;
    }

    td.wrap-text {
        white-space: normal;
    }

    .fa-star {
        cursor: pointer;
    }

    a {
        color: #262626;
    }
    a:hover {
        color: #eabd7a;
    }
}

#survey-management.datatable {
    td:nth-last-child(2) {
        border-right: none;
    }

    th:first-child {
        padding: 1rem 1.4rem !important;
    }
}

#add_survey_form,
#add_job_form {
    i.fa-info-circle {
        color: white;
        cursor: pointer;
    }

    .sections-container {
        .section-row {
            margin-bottom: 10px;
            padding: 8px;
            background-color: white;

            p {
                color: #262626;
                margin-bottom: 0px;
                font-weight: bold;
            }

            i {
                color: #db9123;
            }

            .section-quantity {
                width: 60px;
                justify-content: space-between;

                i {
                    color: #262626;
                    cursor: pointer;

                    &:hover {
                        color: #db9123;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .setting-sidebars {
        &#sidenav_changepassword {
            width: 100vw !important;
        }

        &#sidenav_edituser {
            width: 100vw !important;
        }

        &#sidenav_editorganisation {
            width: 100vw !important;
        }

        &.default {
            width: 100vw !important;
        }
    }
}

.pac-container {
    background-color: #fff;
    z-index: 2001;
    position: fixed;
    display: inline-block;
    float: left;
}

.custom-chip {
    padding: 4px 12px;
    margin-right: 1rem;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    background-color: #f1f1f1;
    border-radius: 16px;
    transition: opacity 0.3s linear;
    word-wrap: break-word;
    box-shadow: none;
    text-transform: none;
    margin-top: 5px;
    margin-bottom: 5px;
}

.duplicate-survey {
    cursor: pointer;
}

.left-actions,
.datatable-table {
    .row-toggle {
        cursor: pointer;
        transition: 0.3s ease;
    }

    .row-toggle.open {
        transform: rotate(180deg);
    }
}

#survey-management {
    // &.datatable th:not(:nth-last-child(3)), &.datatable td:not(:nth-last-child(3)) {
    //     border-right: none;
    // }

    &.datatable th:nth-last-child(2) {
        display: none;
    }
}

.header-options {
    & > *:first-child {
        // flex: 2;
        width: fit-content;
    }

    & > * {
        // flex: 1;
        width: fit-content;
    }
}

.popover {
    border-radius: 0px;
    box-shadow: 0 1.3px 0.8px rgb(0 0 0 / 6%), 0 3.2px 2.4px rgb(0 0 0 / 5%),
        0 5.9px 5.1px rgb(0 0 0 / 4%), 0 10px 9.5px rgb(0 0 0 / 4%),
        0 17px 15.9px rgb(0 0 0 / 3%), 0 32.3px 22.7px rgb(0 0 0 / 3%),
        0 86px 26px rgb(0 0 0 / 2%);
    max-width: 400px;
    .popover-header {
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        color: #373636;
        border-radius: 0px;
        border-bottom: none;
        padding-top: 1rem;
        padding-bottom: 0px;
    }

    .popover-body {
        p {
            margin-top: 10px;
            font-size: 14px;
            font-weight: normal;
            line-height: 21px;
            color: #373636;
            margin-bottom: 0px;
        }
    }
}

#sidenav_addquestion {
    width: 75vw !important;
    #choice_options {
        display: none;
        .form-notch {
            display: none;
        }
    }

    .only-shown {
        font-size: 11px;
        line-height: 21px;
    }

    #question_tags {
        width: 100%;
    }

    .preview-image {
        height: 55px;
        width: 55px;
        object-fit: contain;
    }
}

.tag-question-container {
    .form-outline .form-control ~ .form-notch {
        display: none;
    }

    .chip {
        border-radius: 50px;
        font-size: 12px;
        line-height: 21px;
        height: 21px;
    }

    .chips-padding {
        padding: 0px 5px;
    }
}

.tab-content {
    border: 1px solid white;
    padding: 1.5em 1em;
}

#question_tabs {
    &.nav-tabs {
        margin-bottom: 0px;
    }

    .nav-item {
        position: relative;
        .nav-link {
            font-size: 14px;
            line-height: 16px;
            font-weight: normal;
            color: #ffffff;
            &.active {
                border-left: 1px solid #ffffff;
                border-right: 1px solid #ffffff;
                border-top: 1px solid #ffffff;
                border-bottom: none;
                font-weight: 800;
                text-transform: uppercase;
            }
        }
    }
}

.autocomplete-dropdown-container {
    z-index: 999999;
}

.autocomplete-custom-item-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    i {
        margin-right: 10px;
        color: $my-theme-primary;
    }
}

.modal {
    .section-icon {
        i {
            color: $my-theme-primary;
        }
    }

    .selected-icon {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        i {
            font-size: 40px;
            margin-right: 10px;
            color: $my-theme-primary;
        }
    }
}

.icon-clicktoedit {
    font-size: 12px;
    font-weight: 400;
    text-transform: initial;
}

.change_icon {
    cursor: pointer;
}

.job-address {
    font-size: 12px;
    display: flex;
}

.item-preview-image {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.item-delete {
    font-size: 16px;
    margin-top: -2px;
}

.image-container {
    .lightbox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px 15px;
        width: 100%;
        flex-wrap: wrap;
    }

    .item-uploading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        i {
            font-size: 16px;
            cursor: pointer;
        }
        .uploading {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            top: 0;
            display: flex;
            span {
                color: white;
                font-size: 9px;
                text-align: center;
                margin: auto;
            }
        }
    }
}

// SINGLE JOB VIEW

.main-block {
    background-color: #fff;
    box-shadow: 0 1.3px 0.8px rgb(0 0 0 / 6%), 0 3.2px 2.4px rgb(0 0 0 / 5%), 0 5.9px 5.1px rgb(0 0 0 / 4%), 0 10px 9.5px rgb(0 0 0 / 4%), 0 17px 15.9px rgb(0 0 0 / 3%), 0 32.3px 22.7px rgb(0 0 0 / 3%), 0 86px 26px rgb(0 0 0 / 2%);
}

.job-header {
    p {
        font-weight: bold;
    }

    .surveyor-container {
        margin-bottom: 1rem;

        p {
            margin-bottom: 0px;
        }

        .surveyors {
            a {
                margin-left: 10px;

                i {
                    background-color: #db9123;
                    color: #373636;
                    padding: 10px;
                }
            }
        }
    }
}

#section-management.datatable.crud-datatable {
    input.item-answer {
        border: none;
        background-color: #f1f1f1;
    }

    i.fa-star:not(.selected) {
        color: #808080;
    }

    td[data-mdb-field="original"] i.fa-star {
        cursor: initial;
    }
}

#sidenav_edit_job_reports {
    width: 35vw !important;

    .report-row {
        margin-bottom: 10px;

        .report-name {
            color: #fff;
            font-weight: 700;
            margin-bottom: 0px;
        }

        i {
            padding: 10px;
            margin-left: 10px;
            background-color: #db9123;
        }
    }
}

// SINGLE PAGE
#page-templates .tab-control {
    padding: 30px 40px;
    background-color: transparent;
    width: max-content;
    cursor: pointer;
}

#page-templates .tab-control .h1 {
    text-transform: uppercase !important;
    font-weight: normal !important;
}

#page-templates .tab-control.active {
    cursor: initial;
    background-color: white;
    box-shadow: 0 1.3px 0.8px rgb(0 0 0 / 6%), 0 3.2px 2.4px rgb(0 0 0 / 5%), 0 5.9px 5.1px rgb(0 0 0 / 4%), 0 10px 9.5px rgb(0 0 0 / 4%), 0 17px 15.9px rgb(0 0 0 / 3%), 0 32.3px 22.7px rgb(0 0 0 / 3%), 0 86px 26px rgb(0 0 0 / 2%);
}

#page-templates .tab-control.active .h1 {
    font-weight: 800 !important;
}

#page-templates .tab-body {
    display: none;
}
#page-templates .tab-body.active {
    display: block;
}

#page-templates .tab-body .left {
    border-right: solid 1px #e8e8e8;
}

#page-templates .tab-body .left .template-preview-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;

    .template-preview {
        cursor: pointer;
        position: relative;

        width: 100%;

        .preview {
            background-color: #f1f1f1;
        }

        p {
            margin-bottom: 0px;
        }

        i {
            display: none;
            position: absolute;
            top: 0px;
            right: 0px;
            transform: translate(50%, -50%);
            color: #db9123;
            border-radius: 50%;
            background-color: white;
        }

        &.active {
            i {
                display: block;
            }
        }
    }
}

#sidenav_edit_notes {
    min-width: 510px !important;

    #edit_note_form {
        .wysiwyg-textarea {
            display: none;
        }

        .wysiwyg-content {
            background-color: white;
            min-height: 350px;
        }
    }
}

.report-header {
    label {
        font-weight: bold;
    }

    input#report-name,
    #report-tags {
        width: 100%;
        border: none;
        background-color: #f1f1f1;
        padding: 4px 8px;
    }

    #report-tags {
        min-height: 33px !important;
        padding: 0px;

        .chip {
            border-radius: 40px;
        }

        label {
            display: none !important;
        }

        .form-notch > div {
            border: none !important;
        }
    }
}

#page-templates {
    .template-content {
        display: none;

        &.active {
            display: block;
        }

        label {
            font-weight: bold;
        }

        input:not([type="color"], [type="checkbox"], [type="radio"], .select-input),
        textarea {
            border: none;
            border-radius: 0px;
            background-color: #f1f1f1;
            padding: 4px 8px;
            width: 60%;
        }

        input.select-input {
            border-radius: 0px;
            background-color: #f1f1f1;
            padding: 4px 8px;
        }

        textarea:not(.wysiwyg-textarea) {
            min-height: 200px;
            width: 100%;
        }

        .multi_select-container {
            display: none;

            &.active {
                display: block;
            }
        }

        .page-input-container {
            margin-bottom: 20px;

            .color-select {
                display: none;

                &.active {
                    display: flex;
                    position: absolute;
                    top: 0px;
                    background: #f1f1f1;
                    transform: translateY(100%);
                    z-index: 10;
                }

                .swatch {
                    height: 25px;
                    width: 25px;
                    cursor: pointer;
                }
            }
        }

        .page-input.hidden {
            display: none;
        }

        .operator-select-container,
        .answer-select-container {
            display: none;

            &.active {
                display: block;
            }
        }

        .multiselect-radio-container {
            margin-bottom: 20px;

            label:not(:first-of-type) {
                input {
                    margin-left: 20px;
                }
            }
        }

        .checkbox-container {
            input {
                margin-top: 0px;
                margin-right: 0px;
                margin-left: 20px;
            }
        }

        .checkbox_info {
            position: relative;
            margin-left: 20px;

            span {
                display: none;
                position: absolute;
                bottom: 0px;
                left: 0px;
                transform: translate(-100%, 100%);
                background-color: #db9123;
                padding: 12px;
                font-size: 12px;
                width: max-content;
                max-width: 300px;
                font-weight: bold;
            }
        }
        .placeholder-img-container {
            position: relative;

            &.hidden {
                display: none;
            }

            .placeholder-img {
                width: 60px;
                height: 60px;
                object-fit: cover;
                object-position: center;
            }

            i {
                position: absolute;
                top: 0px;
                right: 0px;
                color: red;
                font-size: 20px;
                cursor: pointer;
            }
        }
    }
}

.single-page-header {
    label {
        font-weight: bold;
    }

    input#page-title {
        border: none;
        border-radius: 0px;
        background-color: #f1f1f1;
        padding: 4px 8px;
    }
}

#sidenav_assetjobs {
    .sidebar-sub {
        color: white;
        font-size: 16px;
        font-weight: bold;
    }

    .table.asset-joblist {
        background: transparent;
        color: #ffffff;
        border-color: #e9e9e9;
        th:first-child,
        td:first-child {
            border-left: none;
        }

        th:last-child,
        td:last-child {
            border-right: none;
        }

        thead tr:first-child {
            border-top: none;
        }

        tbody tr:first-child {
            border-bottom: none;
        }

        th:first-child {
            padding-left: 0px;
        }

        th {
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
        }

        td:first-child {
            padding-left: 0px;
        }

        td {
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
        }

        .no-assets td {
            padding: 1rem 1.4rem;
        }

        .uil.uil-eye {
            color: #db9123;
            margin-left: 5px;
        }

        .status-col {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    width: 40vw !important;
}

.question-indicator,
.rag-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 20px;

    &.red {
        background-color: $red;
    }

    &.amber {
        background-color: $amber;
    }

    &.green {
        background-color: $green;
    }
}

td[data-mdb-field="answer"],
td[data-mdb-field="original"] {
    min-width: 300px;
}

#sidenav_acceptjob {
    .credit-info {
        text-align: center !important;
        text-transform: uppercase;
        color: white;
    }

    #credit-circle {
        width: 92px;
        height: 92px;
        border-radius: 50%;
        color: #373636;
        background-color: white;
        text-transform: uppercase;

        p {
            margin-bottom: 0px;
            font-size: 14px;
            font-weight: 800;
        }
        span {
            font-size: 30px;
            font-weight: 800;
            line-height: 1.5rem;
        }
    }

    #credit-warning {
        border: 1px solid #ffffff;
        text-align: center;
        color: $amber;
        text-transform: uppercase;
        font-weight: 800;
        padding: 10px 0px;
        font-size: 14px;

        p {
            margin-bottom: 0px;
        }
    }
}

.report-page[size="A4"] {
    background: white;
    width: 21cm;
    height: 29.7cm;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
}

#note_images {
    .image-container {
        .note-image {
            position: relative;

            i {
                color: white;
                font-size: 20px;
                cursor: pointer;
            }
        }
    }
}

.image-answer-container {
    i {
        cursor: pointer;
    }
}

#page-management {
    td[data-mdb-field="template_type"] {
        text-transform: capitalize;
    }
}

#async {
    .reset-autocomplete {
        position: absolute;
        top: -2px;
        right: 5px;
        cursor: pointer;
    }
}

.datatable {
    a:not(.sidenav-link):not(.btn):not(.dropdown-item):not(.nav-link):not(.navbar-brand):not(.page-link):not(.carousel-control-prev):not(.carousel-control-next) {
        color: #262626;
    }

    a:not(.sidenav-link):not(.btn):not(.dropdown-item):not(.nav-link):not(.navbar-brand):not(.page-link):not(.carousel-control-prev):not(.carousel-control-next):hover {
        color: #eabd7a;
    }
}

form#edit_survey,
form#edit_section,
form#edit_item {
    input,
    textarea {
        border: none;
        background-color: #f1f1f1;
        padding: 4px 8px;
    }
}

.video-answer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    i {
        margin-top: 10px;
    }
}


#home-dashboard {
    .card {
        height: 100%;
    }
}

#sidenav_editcredits {
    width: 35vw !important;
}

#content .datatable.crud-datatable a.nullify-answer {
    text-transform: uppercase;
    font-size: .85em;
    text-decoration: underline;
    color: #6495ED;
}

.datatable table th, .datatable table td {
    &[data-mdb-field="questions"] {
        white-space: unset;
    }
}
