/**
    SCSS for Registration Page
    Author: Matt Parkes
    Standard SCSS for registration page.
    Images are pulled from the public folder.
**/


.bkg-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    transition: linear all 1s;
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
    img {
        width: 100%;
    }

    .info-text {
        color: white;
        padding: 0px 12%;
        p {
            font-weight: 800;
            font-size: 16px;
        }

        h2 {
            color: white;
        }
    }

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #172943;
        opacity: 80%;
    }
}

.left-content {
    z-index: 1;
    padding: 0% 20%;
}

.form-outline {
    .form-control {
        background-color: #f1f1f1;
        border-radius: 0px;
        &~.form-notch {
            div {
                border: 0px;
                border-radius: 0px !important;
            }
        }
    }
}

.btn {
    border-radius: 0px;
    color: #172943;
    font-size: 14px;
    font-weight: 800;
    line-height: 16px;
}

.form-check-input[type=checkbox] {
    border-radius: 50%;
    background-color: #f1f1f1;
    border-color: #f1f1f1;
}

.form-check-label.optin,
.form-check-label.terms {
    width: 90%;
    text-align: left;
}

#prev-step {
    display: none;
    &.btn-primary {
        background-color: #CBCACA;
    }
}

.row.carddetails {
    --mdb-gutter-x: 0.5rem;
}

.border {
    border-color: #CBCACA;
}

#card-element {
    width: 100%;
}

.floatleft {
    label {
        // float: left;
    }
}

.swiper-container {
    //height: 300px;
}

// .organisationNumberContainer {
//     display: none;
// }

.credit-text {
    font-weight: 800;
    line-height: 16px;
    font-size: 14px;
}

.other-options {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

#submit-step {
    display: none;
}

.error-message {
    font-size: 0.875rem;
    color: #d73522;
}

#card-errors {
    text-align: left;
    font-size: 14px;
    margin-top: -5px;
    color: #d73522;
}

.step-labels {
    span.range-label {
        font-size: 12px; 
        // transform: rotate(45deg); 
        flex: 1; 
        cursor: pointer;
    }

}

#registration-mobile-anchor {
    font-size: 30px;
    color: #DB9120;
    display: none;
}

.registerBtn {
    text-decoration: none !important;
    &:hover {
        color: #ffffff !important;
    }
}

.progress-container {
    .progress {
        height: 5px;
    }
    .progress-label {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
    }
    .progress-bar {
        font-size: 12px;
    }
}

#credits-num {
    span {
        font-size: 12px;
    }
}

.form-outline-select .select-wrapper .select-input {
    background-color: #f1f1f1 !important;
}

@media (max-width: 767px){
    #registration-left,
    #registration-right {
        min-height: 100vh;
    }

    #registration-mobile-anchor {
        display: block;
    }


}

@media (max-width: 425px){
    .left-content {
        padding: 0px;
    }
}

/** 
    End Of SCSS for Registration Page
**/